import React from "react";
import appConstant from "common/constants/appConstant";
import { generateTransactionId } from "common/utilities/ generic";
import { get } from "lodash";
import { Elements } from "prismic-reactjs";

export const prepareStripePayload = (formData) => {
    const {
        cardName,
        email,
        billingCity,
        billingCountry,
        billingAddress1,
        billingAddress2,
        billingState,
        billingZipCode,
    } = formData;

    const cardHolderName = cardName.trim();

    const cardSetupPayload = {
        payment_method_data: {
            billing_details: {
                name: cardHolderName,
                email: email,
                address: {
                    city: billingCity,
                    country: billingCountry,
                    line1: billingAddress1,
                    line2: billingAddress2,
                    state: billingState,
                    postal_code: billingZipCode.trim().toUpperCase(),
                },
            },
        },
    };

    return cardSetupPayload;
};

export const prepareConfirmIntentPayload = (setupIntent, prospectId, country, nameOnCard) => {
    const confirmIntentPayload = {
        prospectId,
        confirmSetupResponse: setupIntent,
        intentId: setupIntent.id,
        country,
        nameOnCard,
    };

    return confirmIntentPayload;
};

const payableAmountWithTaxes = (quotes, billingCycle) => {
    if (billingCycle === appConstant.BILLING_CYCLE_ANNUAL) {
        billingCycle = appConstant.BILLING_CYCLE_ANNUALLY;
    }

    const billingCycleOption = billingCycle.toLowerCase();

    return quotes[0].discountTaxAndServicePremium[billingCycleOption] || 0;
};

const getPaymentOption = (billingCycle) => {
    if (billingCycle === appConstant.BILLING_CYCLE_ANNUALLY) {
        billingCycle = appConstant.BILLING_CYCLE_ANNUAL;
    }

    return billingCycle.toUpperCase();
};

const preparePolicyDetails = (getCustomerQuote) => {
    return getCustomerQuote.reduce((acc, item) => {
        if (item.quotes && item.quotes.length) {
            const billingCycle = getPaymentOption(item.quotes[0].billingFrequency);

            const policyDetails = {
                policyTransactionId: item.cartLineItemId || generateTransactionId(),
                prospectPetId: item.id,
                effectiveDate: item.quotes[0].effectiveDate,
                paymentPlanType: billingCycle,
                premiumWithTaxes: payableAmountWithTaxes(item.quotes, billingCycle),
            };

            acc.push(policyDetails);
        }

        return acc;
    }, []);
};

export const prepareQuoteToPolicyPayload = (cartData, formData, confirmIntentData, platformCustomerUserId) => {
    const cartDetails = get(cartData, "payload.response.cartResponse", {});

    const getCustomerQuote = cartDetails?.customer?.pets.filter((pet) => pet.quotes.length > 0);

    const campaingnDetails = get(getCustomerQuote, "quotes[0].campaignDetails", null);

    const transactionIdUserInterface = generateTransactionId();

    const {
        serviceCode,
        walletId,
        instrumentDetails,
        payMethodId,
        payInMethodStatus = undefined,
        type,
        address,
    } = confirmIntentData;
    const { email, address1, address2, city, state, country } = formData;

    const quoteToPolicyPayload = {
        serviceCode,
        applicationIdentifier: appConstant.APP_ID,
        transactionIdUserInterface,
        appBrandIdentifier: appConstant.BRAND_IDENTIFIER.PP,
        platformCustomerUserId,
        email,
        cartId: cartDetails.id,
        paymentDetails: {
            walletId,
            instrumentDetails,
            payInMethodId: payMethodId,
            payInMethodStatus,
            type,
            paymentType: instrumentDetails?.paymentType,
            billingAddresses: { ...address, type: "BILLING" },
        },
        campaingnDetails,
        holderDetails: [
            {
                type: "PRIMARY",
                firstName: formData.firstName,
                lastName: formData.lastName,
                addresses: [
                    {
                        type: "MAILING",
                        zipcode: formData.zipcode.toUpperCase(),
                        line1: address1,
                        line2: address2,
                        city,
                        state,
                        country,
                    },
                ],
            },
        ],
        policyDetails: preparePolicyDetails(getCustomerQuote),
    };

    return quoteToPolicyPayload;
};

export const htmlSerializer = function (type, element, children, key) {
    var props = {};
    const propsWithUniqueKey = function (props, key) {
        return Object.assign(props || {}, { key });
    };
    switch (type) {
        case Elements.hyperlink:
            props = Object.assign(
                {
                    className: "link-class",
                    href: element.data.url,
                },
                element.data.target ? { target: element.data.target } : { target: "_blank" }
            );
            return React.createElement("a", propsWithUniqueKey(props, key), children);
        default:
            return null;
    }
};
