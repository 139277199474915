import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logger from "common/utilities/logger";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { sendPostMessage } from "common/utilities/eventUtil";
import browserHistory from "services/browserHistory/browserHistory";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };

        this.onReload = this.onReload.bind(this);
    }

    static getDerivedStateFromError(error) {
        logger.error(error);

        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logger.error(error, errorInfo);
    }

    onReload() {
        sendPostMessage("salesFunnel-sdk-cancelled");

        browserHistory.reload();
    }

    render() {
        const { session } = this.props;

        if (this.state.hasError) {
            return (
                <div className={`theme-${session?.currentTheme.toLowerCase()}`}>
                    <div className="app-container">
                        <div className="component-container">
                            <div className="component-card ">
                                <div className="error-boundary-container">
                                    <div className="error-boundary-container__icon">
                                        <img
                                            src="https://fetch-sales-funnel.cdn.prismic.io/fetch-sales-funnel/4fa681b4-42ae-4df7-8c71-20ce754f9c93_icon-magnifying-glass.svg"
                                            alt="error-icon"
                                        />
                                    </div>

                                    <div className="error-boundary-container__heading">Welp, let’s try that again</div>

                                    <div className="error-boundary-container__sub-heading">
                                        {
                                            "Looks like we've encountered an error processing your request. Please try again."
                                        }
                                    </div>

                                    <div className="error-boundary-container__button">
                                        <Button
                                            type="button"
                                            size="large"
                                            onClick={this.onReload}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            className="error-button"
                                            id="refresh"
                                        >
                                            Try again
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

function mapStateToProps(state) {
    return {
        session: state.session,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({}, dispatch),
    };
}

ErrorBoundary.propTypes = {
    children: PropTypes.shape({}),
    session: PropTypes.shape({
        currentTheme: PropTypes.string,
    }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary));
