import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader } from "redux/actions";
import { useHistory } from "react-router-dom";
import ROUTE_CONSTANTS from "common/constants/route";
import { Button } from "@material-ui/core";
import { sendAnalyticsOnLoad } from "common/utilities/analytics";
import pageLoadConfig from "common/utilities/analytics/config/mappings/pageLoadMappings";

const Error = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { cmsData } = useSelector((state) => state.userDetails);

    const { error_icon, error_heading, error_sub_heading, error_button } = cmsData;

    useEffect(() => {
        dispatch(hideLoader({ isLoading: false }));

        sendAnalyticsOnLoad(pageLoadConfig.errorPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onReload = () => {
        history.push(ROUTE_CONSTANTS.ROOT);
    };

    return (
        <div className="error-page-container">
            <div className="error-page-container__icon">
                <img src={error_icon.url} alt={error_icon.alt} />
            </div>

            <div className="error-page-container__heading">{error_heading}</div>

            <div className="error-page-container__sub-heading">{error_sub_heading}</div>

            <div className="error-page-container__button">
                <Button
                    type="button"
                    size="large"
                    onClick={onReload}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className="error-button"
                    id="refresh"
                >
                    {error_button}
                </Button>
            </div>
        </div>
    );
};

export default Error;
