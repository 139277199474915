import { getEnvVar } from "common/utilities/ generic";

const URL_PRE = getEnvVar("REACT_APP_PLATFORM_URL_PRE");

const restEndPointURLs = {
    GET_PROSPECTS: "fdp-prospect-service/v1/prospects",
    GET_CUSTOMER_EXIST: "fdp-customer-service/v1/customers/noauth",
    RE_RATE_CART: `fdp-workflow-service/v1/workflows/carts`,
    SETUP_INTENT: "fdp-wallet-service/v3/wallet/guest/payment-method?service-code={{countryCode}}",
    GET_CUSTOMER_V3: "fdp-customer-service/v3/customers",
    CONFIRM_INTENT: "fdp-wallet-service/v2/wallet/guest/payment-method/{{intentId}}?service-code={{countryCode}}",
    CREATE_POLICY: "fdp-workflow-service/v2/workflows/quotes-to-policy",
};

Object.keys(restEndPointURLs).map((key) => (restEndPointURLs[key] = `/${URL_PRE}/${restEndPointURLs[key]}`));

export default restEndPointURLs;
