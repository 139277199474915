import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";

const preparePath = () => restEndpoints.RE_RATE_CART;

class ReRateCartApi extends BaseRestApi.Api {
    constructor() {
        super({
            getPath: () => preparePath(),
            method: BaseRestApi.methods.POST,
            getRequestPayload: (data) => data,
        });
    }
}

export default ReRateCartApi;
