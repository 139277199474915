const eventActions = {
    EXTERNAL_LINK_CLICKED: "External link click",
    INTERNAL_LINK_CLICKED: "Internal link click",
    TOS_MODAL_OPEN: "Terms of Sale Popup Open ",
    TOS_MODAL_CLOSE: "Terms of Sale Popup Close",
    FIELD_ERROR: "Field_Error",
    FIELD_SUCCESS: "Field_Success",
    PAYMENT_SUBMIT: "Submit",
};

export default eventActions;
