import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Backdrop, makeStyles } from "@material-ui/core";
import lottie from "lottie-web";

const LoadingIndicator = ({ isLoading, loaderMetadata }) => {
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#000",
            backgroundColor: "#fff",
        },
    }));

    const classes = useStyles();

    const animationContainer = useRef();

    const [animationData, setAnimationData] = useState();

    const getLoaderAnimationData = async () => {
        const { loaderAnimation } = loaderMetadata;

        const response = await fetch(loaderAnimation.url);

        const animationResponse = await response.json();

        setAnimationData(animationResponse);
    };

    useEffect(() => {
        if (isLoading) getLoaderAnimationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const animationOptions = {
            loop: false,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
            animationData,
            container: animationContainer.current,
        };

        if (animationData) {
            lottie.loadAnimation(animationOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationData, isLoading]);

    return isLoading ? (
        <div id="loadingIndicator">
            <Backdrop className={classes.backdrop} open={isLoading}>
                <div className="loader">
                    <div className="loader-lottie" ref={animationContainer} />
                </div>
            </Backdrop>
        </div>
    ) : null;
};

LoadingIndicator.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    loaderMetadata: PropTypes.object,
};

export default LoadingIndicator;
