export default {
    API_VERSION: /\/v[1-9]{1}\//,
    WHITE_SPACE_EXPRESSION: /\s+/g,
    US_ZIP_CODE_PATTERN: "^\\d{5}(-{0,1}\\d{4})?$",
    CAN_ZIP_CODE_PATTERN: /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i,
    CUSTOMER_REFERENCE: "CUSTOMERID",
    APP_ID: "fetchpurchasewidget",
    APPLICATION_IDENTIFIER: "SALES_FUNNEL",
    SOURCE_TYPE: "fetchAPI",
    COUNTRY_CODE_CANADA: "CAN",
    COUNTRY_CODE_CA: "CA",
    COUNTRY_CODE_US: "US",
    CANADIAN_DOLLAR: "CAD",
    ISO_DATE: "YYYY-MM-DDTHH:mm:ss.SSSZ",
    US_DOLLAR: "USD",
    BILLING_CYCLE_ANNUALLY: "annually",
    BILLING_CYCLE_QUARTERLY: "quarterly",
    BILLING_CYCLE_MONTHLY: "monthly",
    BILLING_CYCLE_ANNUAL: "ANNUAL",
    LOGGER: {
        LOGGER_NAME: "SalesFunnel-sdk",
        DEFAULT_LOG_LEVEL: 1,
        FILTERS: {
            TEST_VERSION: "test_version",
        },
    },
    ENVIRONMENTS: {
        DEVELOPMENT: "DEV",
    },
    CANADA_SIGN: "C",
    DOLLAR_SIGN: "$",
    SOURCE: {
        FETCH_BUY: "fetchtest",
    },
    ROUTE_TYPES: {
        PUBLIC: "publicRoute",
    },
    CONTACTS_TYPE: {
        MOBILE: "MOBILE",
    },
    BRAND_IDENTIFIER: {
        PP: "PP",
        WAL: "WAL",
    },
    PAYMENT_METHOD: {
        CARD: "card",
        US_BANK: "us_bank_account",
    },
    PAYMENT_STATUS: {
        APPROVED: "APPROVED",
        PROCESSING: "PROCESSING",
    },
};
