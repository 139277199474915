import userDetailsActionType from "./type";

export const setProspectDetails = (data) => {
    return {
        type: userDetailsActionType.PROSPECT_DATA,
        data,
    };
};

export const setCmsData = (data) => {
    return {
        type: userDetailsActionType.CMS_DATA,
        data,
    };
};

export const setSdkConfig = (data) => {
    return {
        type: userDetailsActionType.SDK_CONFIG,
        data,
    };
};

export const setCustomerExists = (data) => {
    return {
        type: userDetailsActionType.CUSTOMER_EXISTS,
        data,
    };
};

export const setCartDetails = (data) => {
    return {
        type: userDetailsActionType.CART_DATA,
        data,
    };
};

export const setCustomerDetails = (data) => {
    return {
        type: userDetailsActionType.CUSTOMER_DATA,
        data,
    };
};

export const setConfirmIntentData = (data) => {
    return {
        type: userDetailsActionType.CONFIRM_INTENT_DATA,
        data,
    };
};

export const setConfirmScreenData = (data) => {
    return {
        type: userDetailsActionType.CONFIRM_SCREEN_DATA,
        data,
    };
};
