import { get } from "lodash";
import {
    getBillingCycle,
    getBreedNames,
    getCartLineItemId,
    getDeductibles,
    getEffectiveDates,
    getPayouts,
    getPetAges,
    getPetTypes,
    getReimbursements,
} from "../../utils/petDataMapper";
import eventActions from "../values/eventActions";
import customEventAnalyticsMapping from "./customEventMappings";

export const getErrorInputFieldMapping = (action, value) => {
    let mapping = customEventAnalyticsMapping.InputFieldError;
    mapping.eventAction = `CardField_${action}`;
    mapping.eventLabel = value ? eventActions.FIELD_SUCCESS : eventActions.FIELD_ERROR;

    return mapping;
};

export const getTosClickedMapping = (action) => {
    let mapping = customEventAnalyticsMapping.healthReportFormClicked;

    mapping.eventAction = action ? eventActions.TOS_MODAL_OPEN : eventActions.TOS_MODAL_CLOSE;

    return mapping;
};

export const getInternalLinkMapping = (destinationUrl) => {
    const mapping = customEventAnalyticsMapping.internalLinkClick;

    mapping.eventLabel = destinationUrl;

    return mapping;
};

export const getFieldMappingForPayment = (data, userDetails) => {
    const mapping = customEventAnalyticsMapping.paymentSubmit;

    const productData = get(userDetails, "pets", []);
    const petsData = productData.filter((item) => item.quotes.length && item.quotes);
    const promoCode = get(petsData, "quotes[0].campaignDetails.code", false);

    mapping.eventLabel = data;
    mapping.petType = getPetTypes(petsData).join(",");
    mapping.breedNames = getBreedNames(petsData).join(",");
    mapping.petAge = getPetAges(petsData).join(",");
    mapping.annualPayout = getPayouts(petsData).join(",");
    mapping.annualDeductible = getDeductibles(petsData).join(",");
    mapping.reimbursementAmount = getReimbursements(petsData).join(",");
    mapping.coverageBeginsDate = getEffectiveDates(petsData).join(",");
    mapping.preferredBillingCycle = getBillingCycle(petsData).join(",");
    mapping.cartLineItemId = getCartLineItemId(petsData).join(",");
    mapping.promoCode = promoCode;

    return mapping;
};
