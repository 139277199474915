export default {
    userDetails: {
        prospectData: {},
        cmsData: {},
        sdkConfig: {
            prospectId: "",
        },
        existingCustomer: {
            isExist: false,
        },
        cartData: {},
        customerData: {},
        confirmIntentData: {},
        confirmScreenData: {},
    },
    common: {
        loadingIndicator: {
            isLoading: false,
        },
    },
    session: {
        currentTheme: "DEFAULT",
    },
};
