import appConstant from "common/constants/appConstant";
import ROUTE_CONSTANTS from "common/constants/route";
import Confirmation from "components/confirmation";
import RetrieveQuote from "components/retrieve-quote";
import Error from "../components/error";

const appRoutes = [
    {
        path: ROUTE_CONSTANTS.ROOT,
        component: RetrieveQuote,
        exact: true,
        type: appConstant.ROUTE_TYPES.PUBLIC,
    },
    {
        path: ROUTE_CONSTANTS.ERROR,
        component: Error,
        exact: true,
        type: appConstant.ROUTE_TYPES.PUBLIC,
    },
    {
        path: ROUTE_CONSTANTS.CONFIRMATION,
        component: Confirmation,
        exact: true,
        type: appConstant.ROUTE_TYPES.PUBLIC,
    },
];

export default appRoutes;
