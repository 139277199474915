import appConstant from "common/constants/appConstant";
import { isAndroid, isIOS } from "common/utilities/ generic";
import { sendPostMessage } from "common/utilities/eventUtil";
import logger from "common/utilities/logger";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader } from "redux/actions";

const Confirmation = () => {
    const dispatch = useDispatch();

    const { cmsData, confirmScreenData } = useSelector((state) => state.userDetails);

    const {
        fetch_logo,
        lock_icon,
        secured_message,
        stripe_icon,
        card_confirm_heading,
        card_confirm_sub_heading,
        card_confirm_icon,
        card_confirm_ios_icon,
        card_confirm_android_icon,
        bank_sign_in_heading,
        bank_sign_in_next,
        bank_sign_in_text,
        bank_manual_heading,
        bank_manual_next,
        bank_manual_text1,
        bank_manual_text2,
        google_store_link,
        apple_store_link,
        close_icon,
    } = cmsData;

    useEffect(() => {
        dispatch(hideLoader({ isLoading: false }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEmpty(confirmScreenData)) {
            logger.info(`Rendered Confirmation component successfully with ${confirmScreenData?.paymentMethod}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmScreenData]);

    const closeWidget = () => {
        logger.info("send Post Message with customerID:", confirmScreenData?.customerID);

        sendPostMessage("salesFunnel-sdk-success", confirmScreenData?.customerID);
    };

    const onClickVerify = () => {
        window.open(confirmScreenData?.nextAction?.verify_with_microdeposits?.hosted_verification_url, "_blank");
    };

    const getAppDownloadLinkForFetch = () => {
        return (
            <>
                {!isAndroid() && (
                    <a href={apple_store_link} target="__blank" className="ios-button">
                        <img src={card_confirm_ios_icon.url} alt={card_confirm_ios_icon.alt} />
                    </a>
                )}
                {!isIOS() && (
                    <a href={google_store_link} target="__blank" className="android-button">
                        <img src={card_confirm_android_icon.url} alt={card_confirm_android_icon.alt} />
                    </a>
                )}
            </>
        );
    };

    return (
        <div className="confirmation">
            <div className="confirmation__container">
                <div className="confirmation__container__close-button">
                    {/* eslint-disable-next-line */}
                    <a href="#" onClick={() => closeWidget()}>
                        <img src={close_icon.url} alt={close_icon.alt} />
                    </a>
                </div>
                <div className="confirmation__container__logo">
                    <img src={fetch_logo.url} alt={fetch_logo.alt} />
                </div>
                {confirmScreenData?.paymentMethod === appConstant.PAYMENT_METHOD.US_BANK &&
                    confirmScreenData?.status !== "succeeded" && (
                        <>
                            <div
                                className="confirmation__container__heading-manual"
                                dangerouslySetInnerHTML={{
                                    __html: bank_manual_heading,
                                }}
                            />
                            <div className="confirmation__container__sub-heading-manual">
                                <div className="confirmation__container__sub-heading-manual__text-heading">
                                    {bank_manual_next}
                                </div>
                                <div className="confirmation__container__sub-heading-manual__text">
                                    <ul>
                                        <li>{bank_manual_text1}</li>
                                        <li>{bank_manual_text2}</li>
                                        {!confirmScreenData?.liveMode && (
                                            <li>
                                                {/* eslint-disable-next-line */}
                                                <a href="#" onClick={() => onClickVerify()}>
                                                    Click to verify with micro-deposits
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="confirmation__container__secure">
                                <img src={lock_icon.url} alt={lock_icon.alt} className="lock-icon" />
                                <span className="secured_message">{secured_message}</span>
                                <img src={stripe_icon.url} alt={stripe_icon.alt} />
                            </div>
                        </>
                    )}

                {confirmScreenData?.paymentMethod === appConstant.PAYMENT_METHOD.US_BANK &&
                    confirmScreenData?.status === "succeeded" && (
                        <>
                            <div
                                className="confirmation__container__heading-sign-in"
                                dangerouslySetInnerHTML={{
                                    __html: bank_sign_in_heading,
                                }}
                            />

                            <div className="confirmation__container__sub-heading">
                                <div className="confirmation__container__sub-heading__text-heading">
                                    {bank_sign_in_next}
                                </div>
                                <div className="confirmation__container__sub-heading__text">{bank_sign_in_text}</div>
                            </div>
                            <div className="confirmation__container__secure">
                                <img src={lock_icon.url} alt={lock_icon.alt} className="lock-icon" />
                                <span className="secured_message">{secured_message}</span>
                                <img src={stripe_icon.url} alt={stripe_icon.alt} />
                            </div>
                        </>
                    )}

                {confirmScreenData?.paymentMethod === appConstant.PAYMENT_METHOD.CARD && (
                    <>
                        <div className="confirmation__container__enroll">
                            <img src={card_confirm_icon.url} alt={card_confirm_icon.alt} />
                        </div>
                        <div className="confirmation__container__heading">{card_confirm_heading}</div>
                        <div className="confirmation__container__sub-heading__text">{card_confirm_sub_heading}</div>
                        <div className="confirmation__container__app-btn">{getAppDownloadLinkForFetch()}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Confirmation;
