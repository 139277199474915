import React, { useEffect } from "react";
import PrismicService from "./services/cms";
import { getPostMessage } from "./common/utilities/eventUtil";
import logger from "common/utilities/logger";
import { Switch, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import routes from "../src/router";
import RouteHandler from "components/common/Routes/RouteHandler";
import { setCmsData, setSdkConfig } from "redux/actions";
import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";
import { isEmpty } from "lodash";

function App() {
    const dispatch = useDispatch();
    let isDataFound = false;

    const { sdkConfig, cmsData } = useSelector((state) => state.userDetails);
    const { loadingIndicator } = useSelector((state) => state.common);
    const { currentTheme } = useSelector((state) => state.session);

    useEffect(() => {
        getPostMessage((data) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            isDataFound = true;
            dispatch(setSdkConfig(data));

            logger.info("SalesFunnel sdk value", data.prospectId);
        });

        setTimeout(() => {
            if (!isDataFound) {
                logger.error("Failed to load purchase widgets", sdkConfig?.prospectId);
            }
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sdkConfig.prospectId) {
            logger.info("Initialization for salesFunnel payment-widget");

            const preloadContent = async () => {
                const value = await PrismicService.getDocWithType("payment_widget_sdk");

                dispatch(setCmsData(value.data));
            };

            preloadContent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sdkConfig]);

    const renderRoutes = () => {
        const routesToRender = [...routes.map((route, i) => <RouteHandler key={i} {...route} />)];
        return routesToRender;
    };

    return (
        <>
            <div className={`theme-${currentTheme.toLowerCase()}`}>
                {loadingIndicator?.isLoading && (
                    <LoadingIndicator
                        key="loading-indicator"
                        isLoading={loadingIndicator.isLoading}
                        loaderMetadata={loadingIndicator.loaderMetadata}
                    />
                )}
                <div className="app-container">
                    <div className="component-container">
                        <div className="component-card ">
                            <Switch>{sdkConfig.prospectId && !isEmpty(cmsData) && renderRoutes()}</Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(App);
