import storage from "./localStorage";
import { getCurrentTimeInMs } from "./date";
import { v4 as uuidv4 } from "uuid";
import logger from "./logger";

const UNIQUE_APP_USER_ID = "_xAppCallId";

export const getUniqueAppUserId = () => {
    try {
        let id = storage.local.get(UNIQUE_APP_USER_ID);
        if (!id) {
            id = uuidv4();
            storage.local.set(UNIQUE_APP_USER_ID, id);
        }

        logger.info("Successfully access storage generating uuid");

        return id;
    } catch (error) {
        logger.info("Failed to access storage falling back to uuid", error);

        return uuidv4();
    }
};

export const getCorrelationId = () => {
    return `SFSDK-${getUniqueAppUserId()}-${getCurrentTimeInMs()}`;
};
