export default {
    PROSPECT_DATA: "PROSPECT_DATA",
    CMS_DATA: "CMS_DATA",
    SDK_CONFIG: "SDK_CONFIG",
    CUSTOMER_EXISTS: "CUSTOMER_EXISTS",
    CART_DATA: "CART_DATA",
    CUSTOMER_DATA: "CUSTOMER_DATA",
    CONFIRM_INTENT_DATA: "CONFIRM_INTENT_DATA",
    CONFIRM_SCREEN_DATA: "CONFIRM_SCREEN_DATA",
};
