import { get } from "lodash";

const AGE_LESS_THAN_ONE = "6 weeks to 11 months";

export const getBreedNames = (pets) => {
    const breedNames = pets.map((pet) => {
        const names = pet.breedName;
        return names;
    });

    return breedNames;
};

export const getPetTypes = (pets) => {
    const types = pets.map((pet) => pet.petType);

    return types;
};

export const getCartLineItemId = (pets) => {
    const cartLineItemId = pets.map((pet) => pet.cartLineItemId);

    return cartLineItemId;
};

export const getAppendedAge = (age) => {
    if (age === 0) {
        age = AGE_LESS_THAN_ONE;
    } else if (age === 1) {
        age = `${age} year`;
    } else {
        age = `${age} years`;
    }

    return age;
};

export const getPetAges = (pets) => {
    const ages = pets.map((pet) => {
        let age = get(pet, "dob.petAge");
        age = getAppendedAge(age);
        return age;
    });

    return ages;
};

export const getPayouts = (pets) => {
    const quotes = pets.map((pet) => pet.quotes[0]);

    const payouts = quotes.map((quote) => quote.annualPolicyMaximumLimit);

    return payouts;
};

export const getDeductibles = (pets) => {
    const quotes = pets.map((pet) => pet.quotes[0]);

    const deductibles = quotes.map((quote) => quote.annualDeductibleDollarAmount);

    return deductibles;
};

export const getReimbursements = (pets) => {
    const quotes = pets.map((pet) => pet.quotes[0]);

    const reimbursements = quotes.map((quote) => quote.coPayPercentage);

    return reimbursements;
};

export const getEffectiveDates = (pets) => {
    const quotes = pets.map((pet) => pet.quotes[0]);

    const effectiveDates = quotes.map((quote) => quote.effectiveDate);

    return effectiveDates;
};

export const getBillingCycle = (pets) => {
    const quotes = pets.map((pet) => pet.quotes[0]);

    const billingCycle = quotes.map((quote) => quote.billingFrequency);

    return billingCycle;
};
