import events from "../values/events";
import eventCategories from "../values/eventCategories";
import eventActions from "../values/eventActions";

const commonCustomTags = {
    petType: null,
    breedNames: null,
    petAge: null,
};

const pageEventConfig = {
    healthReportFormClicked: {
        event: events.PURCHASE_WIDGET_SDK_TOS,
        eventCategory: eventCategories.PURCHASE_WIDGET_SDK_TOS,
        eventAction: null,
        eventLabel: null,
    },
    internalLinkClick: {
        event: events.INTERNAL_LINK,
        eventCategory: eventCategories.LINK_TRACKING,
        eventAction: eventActions.INTERNAL_LINK_CLICKED,
        eventLabel: null,
    },
    paymentSubmit: {
        event: events.PAYMENT_SUBMIT,
        eventCategory: eventCategories.PURCHASE_WIDGET_SDK_FORM_SUBMIT,
        eventAction: eventActions.PAYMENT_SUBMIT,
        eventLabel: null,
        coverageBeginsDate: null,
        annualPayout: null,
        annualDeductible: null,
        reimbursementAmount: null,
        preferredBillingCycle: null,
        cartLineItemId: null,
        promoCode: null,
        ...commonCustomTags,
    },
    InputFieldError: {
        event: events.CARD_FIELD_ERROR,
        eventCategory: eventCategories.CARD_FIELD_ERROR,
        eventAction: null,
        eventLabel: null,
    },
};

export default pageEventConfig;
