import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";

const preparePath = (data) => `${restEndpoints.GET_PROSPECTS}/${data}`;

class GetProspects extends BaseRestApi.Api {
    constructor() {
        super({
            getPath: (data) => preparePath(data),
            method: BaseRestApi.methods.GET,
            getRequestPayload: () => {},
        });
    }
}

export default new GetProspects();
