import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";
import appConstant from "../../../common/constants/appConstant";
import { getPaymentType } from "common/utilities/ generic";

const _preparePath = (intentId, country) => {
    return restEndpoints.CONFIRM_INTENT.replace("{{intentId}}", intentId).replace("{{countryCode}}", country);
};

const _prepareRequestConfig = (Authorization) => {
    return {
        headers: {
            Authorization,
        },
    };
};
const _preparePayload = ({
    prospectId = null,
    confirmSetupResponse = {},
    nameOnCard = null,
    country,
    showBankAccount,
    paymentTypes,
}) => {
    return {
        application: {
            name: appConstant.APP_ID,
            identifier: appConstant.APP_ID,
        },
        product: {
            type: "insurance",
            identifier: "Comprehensive_PetInsurance",
        },
        paymentType: getPaymentType(showBankAccount, country),
        confirmSetupResponse,
        externalReferenceId: prospectId,
        externalReferenceType: appConstant.CUSTOMER_REFERENCE,
        appId: appConstant.APP_ID,
        ...(paymentTypes === appConstant.PAYMENT_METHOD.CARD && {
            nameOnCard: nameOnCard,
        }),
        ...(paymentTypes === appConstant.PAYMENT_METHOD.US_BANK && {
            accountHolderName: nameOnCard,
        }),
    };
};

class ConfirmIntentApi extends BaseRestApi.Api {
    constructor(
        Authorization,
        { prospectId, confirmSetupResponse, intentId, country, nameOnCard },
        showBankAccount,
        paymentTypes
    ) {
        super({
            getPath: () => _preparePath(intentId, country),
            method: BaseRestApi.methods.PUT,
            getRequestConfig: () => _prepareRequestConfig(Authorization),
            getRequestPayload: () =>
                _preparePayload({
                    prospectId,
                    confirmSetupResponse,
                    nameOnCard,
                    country,
                    showBankAccount,
                    paymentTypes,
                }),
        });
    }
}

export default ConfirmIntentApi;
