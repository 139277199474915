import BaseRestApi from "../BaseRestApi";
import restEndpoints from "../restEndPointURLs";
import appConstant from "../../../common/constants/appConstant";
import { getPaymentType } from "common/utilities/ generic";

const _preparePath = (country) => {
    return restEndpoints.SETUP_INTENT.replace("{{countryCode}}", country.country);
};

const _prepareRequestConfig = (Authorization) => {
    return {
        headers: {
            Authorization,
        },
    };
};

const _preparePayload = ({ prospectId = null, country, showBankAccount }) => {
    return {
        application: {
            name: appConstant.APP_ID,
            identifier: appConstant.APP_ID,
        },
        product: {
            type: "insurance",
            identifier: "Comprehensive_PetInsurance",
        },
        paymentType: getPaymentType(showBankAccount,country),
        externalReferenceId: prospectId,
        externalReferenceType: appConstant.CUSTOMER_REFERENCE,
        appId: appConstant.APP_ID,
    };
};

class SetupIntentApi extends BaseRestApi.Api {
    constructor(Authorization, prospectId, country, showBankAccount) {
        super({
            getPath: () => _preparePath({ country }),
            method: BaseRestApi.methods.POST,
            getRequestConfig: () => _prepareRequestConfig(Authorization),
            getRequestPayload: () => _preparePayload({ prospectId, country, showBankAccount }),
        });
    }
}

export default SetupIntentApi;
