import { userDetailsActionType } from "../../actions";
import initialState from "../initialState";

export default function userDetailsReducer(state = initialState.userDetails, action) {
    switch (action.type) {
        case userDetailsActionType.PROSPECT_DATA:
            return {
                ...state,
                prospectData: action.data,
            };
        case userDetailsActionType.CMS_DATA:
            return {
                ...state,
                cmsData: action.data,
            };
        case userDetailsActionType.SDK_CONFIG:
            return {
                ...state,
                sdkConfig: action.data,
            };
        case userDetailsActionType.CUSTOMER_EXISTS:
            return {
                ...state,
                existingCustomer: action.data,
            };
        case userDetailsActionType.CART_DATA:
            return {
                ...state,
                cartData: action.data,
            };
        case userDetailsActionType.CUSTOMER_DATA:
            return {
                ...state,
                customerData: action.data,
            };
        case userDetailsActionType.CONFIRM_INTENT_DATA:
            return {
                ...state,
                confirmIntentData: action.data,
            };
        case userDetailsActionType.CONFIRM_SCREEN_DATA:
            return {
                ...state,
                confirmScreenData: action.data,
            };
        default:
            return state;
    }
}
